import "simplebar/dist/simplebar.min.css";
import {createApp} from 'vue'
import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from './App.vue'
import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";
import router from './router'
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {createI18n} from 'vue-i18n'
import englishLang from '@/locales/en.json'
import farsiLang from '@/locales/fa.json'
import "./assets/localeFont.css"
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import {abilitiesPlugin} from '@casl/vue';
import {Ability} from '@casl/ability';
import {createAbility} from './services/ability';

const i18n = createI18n({
    legacy: false,
    locale: 'fa', // Set your default locale here
    fallbackLocale: 'fa',
    messages: {
        en: englishLang, // Path to the English translation file
        fa: farsiLang, // Path to the Farsi translation file
    },
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
app.use(pinia)
app.use(Toast, {
    toastClassName: "dashcode-toast",
    bodyClassName: "dashcode-toast-body",
})
import {useUserStore} from '@/stores/user';

const userStore = useUserStore();

// Create ability based on user permissions
const ability = new Ability(createAbility(userStore.permissions || []));
app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
});
app.use(router)
app.use(VueTippy)
app.use(VueGoodTablePlugin);
app.use(i18n)
app.use(VueViewer)
app.config.globalProperties.$store = {};

app.mount('#app')

import {useThemeSettingsStore} from "@/stores/themeSettings";

const themeSettingsStore = useThemeSettingsStore()
i18n.global.locale.value = themeSettingsStore.getLanguage

document.body.setAttribute('data-lang', themeSettingsStore.getLanguage)
// check localStorage theme for dark light bordered
if (localStorage.theme === "dark") {
    document.body.classList.add("dark");
    themeSettingsStore.theme = "dark";
    themeSettingsStore.isDark = true;
} else {
    document.body.classList.add("light");
    themeSettingsStore.theme = "light";
    themeSettingsStore.isDark = false;
}
if (localStorage.semiDark === "true") {
    document.body.classList.add("semi-dark");
    themeSettingsStore.semidark = true;
    themeSettingsStore.semiDarkTheme = "semi-dark";
} else {
    document.body.classList.add("semi-light");
    themeSettingsStore.semidark = false;
    themeSettingsStore.semiDarkTheme = "semi-light";
}
// check local storage for menuLayout
if (localStorage.menuLayout === "horizontal") {
    themeSettingsStore.menuLayout = "horizontal";
} else {
    themeSettingsStore.menuLayout = "vertical";
}

// check skin  for localstorage
if (localStorage.skin === "bordered") {
    themeSettingsStore.skin = "bordered";
    document.body.classList.add("skin--bordered");
} else {
    themeSettingsStore.skin = "default";
    document.body.classList.add("skin--default");
}
// check direction for localstorage
if (themeSettingsStore.direction) {
    document.documentElement.setAttribute("dir", "rtl");
} else {
    document.documentElement.setAttribute("dir", "ltr");
}

// Check if the monochrome mode is set or not
if (localStorage.getItem('monochrome') !== null) {
    themeSettingsStore.monochrome = true;
    document.getElementsByTagName('html')[0].classList.add('grayscale');
}
