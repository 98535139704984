import {useUserStore} from "@/stores/user";
import {useAbility} from '@casl/vue';

export default function auth({to, from, next, store, gate}) {
    const userStore = useUserStore();
    const userCredential = userStore.getCredential;
    const ability = useAbility();

    if (userCredential) {
        if (ability.can('access', to.name)) {
            return next();
        } else if (to.name !== 'forbidden') {
            return next({ name: 'forbidden' });
        }
    } else {
        userStore.resetUser();
        return next({ name: 'Login' });
    }
    return next();

}
