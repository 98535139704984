/* eslint-disable */
import api from "@/api";
import router from "@/router";
import {defineStore} from "pinia/dist/pinia";
import packageJson from "../../package.json";

export const client = {
    appName: "Boostaminophen",
    platform: "Web",
    market: "Direct",
    language: "EN",
    version: packageJson.version
}

export const useUserStore = defineStore('user', {
    state: () => ({
        username: '',
        password: '',
        credential: '',
        id: 0,
        permissions: [],
        profile: {},
        activeSessions: [],
        errorMessage: '',
        preferences: {
            games: {
                boost: [],
                account: [],
                currencyBuy: [],
                currencySell: [],
            }
        },
        userCompleteProfile: {}
    }),
    getters: {
        getCredential(state) {
            return state.credential;
        },
        getUserId(state) {
            return state.id;
        },
        getUsername(state) {
            return state.username;
        },
        getProfileAttribute(state) {
            return (attribute) => {
                if (attribute in state.profile) {
                    return state.profile[attribute];
                }
                //console.warn(`Attribute "${attribute}" does not exist in profile`);
                return undefined;
            };
        },
        getProfile: (state) => state.profile,
        getPermissions(state) {
            return state.permissions;
        },
        getPassword(state) {
            return state.password;
        },
        getErrorMessage(state) {
            return state.errorMessage;
        },
        getGamePreferences(state) {
            return state.preferences.games;
        },
        getBoostGamePreferences(state) {
            return state.preferences.games.boost;
        },
        getAccountGamePreferences(state) {
            return state.preferences.games.account;
        },
        getCurrencyBuyGamePreferences(state) {
            return state.preferences.games.currencyBuy;
        },
        getCurrencySellGamePreferences(state) {
            return state.preferences.games.currencySell;
        },
        getActiveSessions(state) {
            return state.activeSessions;
        },
        getUserCompleteProfile(state) {
            return state.userCompleteProfile;
        },
    },
    actions: {
        async login(payload) {
            const request = {
                "request": 1008,
                "account": {
                    "username": payload.username,
                    "password": payload.password
                },
                "client": client
            };
            //console.log(request)
            await api.post('', request)
                .then((res) => {
                    if (res.status === 200) {
                        return res.data
                    } else {
                        console.log('response is ' + res.status)
                    }
                }).then(async (data) => {
                    if (data.response === 100) {
                        this.credential = data.account.credential
                        this.id = data.account.id
                        this.username = data.account.username
                        await this.action_getBasicProfile();
                    } else {
                        this.credential = ''
                        this.username = ''
                        this.id = 0
                        this.errorMessage = data.message
                    }
                })

        },
        async action_getBasicProfile() {
            console.log('getBasicProfile called')
            const request = {
                "request": 1009,
                "subRequest": 0,
                "account": {
                    "id": this.id,
                    "credential": this.credential
                },
                "client": client
            };
            return await api.post('', request)
                .then((res) => {
                    if (res.status === 200) {
                        return res.data
                    } else {
                        console.log('response is ' + res.status)
                    }
                }).then((data) => {
                    console.log("getBasicProfile: ")
                    console.log(data.account)
                    if (data.response === 100) {
                        this.profile = data.account
                        this.permissions = data.account.permissions
                        this.username = data.account.username
                        //console.log('permissions: ')
                        //console.log(this.permissions)
                    } else if (data.response === -100002) {
                        this.resetUser('your credential is expired')
                        router.push({name: 'Login'})
                    } else {
                        this.errorMessage = data.message
                        return false
                    }
                })

        },
        async saveIdentificationDocuments(payload) {
            const request = {
                "request": 1009,
                "subRequest": 1016,
                "account": {
                    "id": this.id,
                    "credential": this.credential,
                    "firstName": payload.firstName,
                    "lastName": payload.lastName,
                    "address": payload.address,
                    "phoneNumber": payload.phoneNumber,
                    "kycIdCard": payload.kycIdCard.action ? {
                        "action": "replace",
                        "name": payload.kycIdCard.name
                    } : {
                        "name": payload.kycIdCard.name
                    },
                    "kycSelfie": payload.kycSelfie.action ? {
                        "action": "replace",
                        "name": payload.kycSelfie.name
                    } : {
                        "name": payload.kycSelfie.name
                    }
                },
                "client": client
            };
            console.log('request: ')
            console.log(request)
            return await api.post('', request)
                .then((res) => {
                    if (res.status === 200) {
                        return res.data
                    } else {
                        console.log('response is ' + res.status)
                    }
                }).then((data) => {
                    console.log("saveIdentificationDocuments: ")
                    console.log(data)
                    if (data.response === 100) {
                        //console.log("saveIdentificationDocuments: " + data)
                        console.log("saveIdentificationDocuments successfully")
                        return true
                    } else if (data.response === -100002) {
                        this.resetUser('your credential is expired')
                        router.push({name: 'Login'})
                    } else {
                        this.errorMessage = data.message
                        return false
                    }
                })

        },
        async action_getCompleteProfile() {
            //console.log('getCompleteProfile called')
            const request = {
                "request": 1009,
                "subRequest": 1017,
                "account": {
                    "id": this.id,
                    "credential": this.credential
                },
                "client": client
            };
            //console.log('request: ')
            //console.log(request)
            return await api.post('', request)
                .then((res) => {
                    if (res.status === 200) {
                        return res.data
                    } else {
                        console.log('response is ' + res.status)
                    }
                }).then((data) => {
                    console.log("getCompleteProfile: ")
                    console.log(data.account)
                    if (data.response === 100) {
                        this.profile = data.account
                        this.permissions = data.account.permissions
                        this.username = data.account.username
                    } else if (data.response === -100002) {
                        this.resetUser('your credential is expired')
                        router.push({name: 'Login'})
                    } else {
                        this.errorMessage = data.message
                        return false
                    }
                })

        },
        async action_getUserCompleteProfile(payload) {
            //console.log('getCompleteProfile called')
            const request = {
                "request": 1009,
                "subRequest": 10018,
                "account": {
                    "id": this.id,
                    "credential": this.credential
                },
                "profile": {
                    "id": payload.userId
                },
                "client": client
            };
            console.log('request: ')
            console.log(request)
            return await api.post('', request)
                .then((res) => {
                    if (res.status === 200) {
                        return res.data
                    } else {
                        console.log('response is ' + res.status)
                    }
                }).then((data) => {
                    console.log("getUserCompleteProfile: ")
                    console.log(data.profile)
                    if (data.response === 100) {
                        this.userCompleteProfile = data.profile
                    } else if (data.response === -100002) {
                        this.resetUser('your credential is expired')
                        router.push({name: 'Login'})
                    } else {
                        this.errorMessage = data.message
                        return false
                    }
                })

        },
        async action_loginWithDiscord(payload) {
            const request = {
                "request": 1007,
                "account": {
                    "code": payload.code
                },
                "client": client
            };
            console.log(request)
            await api.post('', request)
                .then((res) => {
                    if (res.status === 200) {
                        return res.data
                    } else {
                        console.log('response is ' + res.status)
                    }
                }).then(async (data) => {
                    console.log(data)
                    if (data.response === 100) {
                        this.credential = data.account.credential
                        this.id = data.account.id

                    } else {
                        this.credential = ''
                        this.username = ''
                        this.id = 0
                        this.errorMessage = data.message
                    }
                })

        },
        setErrorMessage(payload) {
            this.errorMessage = payload
        },
        setBoostGamePreferences(payload) {
            this.preferences.games.boost = payload
        },
        setCurrencyBuyGamePreferences(payload) {
            this.preferences.games.currencyBuy = payload
        },
        setCurrencySellGamePreferences(payload) {
            this.preferences.games.currencySell = payload
        },
        setAccountGamePreferences(payload) {
            this.preferences.games.account = payload
        },
        setCredential(payload) {
            this.credential = payload
        },
        setUsername(payload) {
            this.username = payload
        },
        setUserId(payload) {
            this.id = payload
        },
        async action_getActiveSessions() {
            const request = {
                "request": 1009,
                "subRequest": 10013,
                "account": {
                    "id": this.id,
                    "credential": this.credential
                },
                "client": client
            };
            await api.post('', request)
                .then((res) => {
                    if (res.status === 200) {
                        return res.data
                    } else {
                        console.log('response is ' + res.status)
                    }
                }).then((data) => {
                    //console.log('action_getActiveSessions', data)
                    if (data.response === 100) {
                        this.activeSessions = data.account.sessions;
                    } else if (data.response === -100002) {
                        this.resetUser('your credential is expired');
                        router.push({name: 'Login'})
                    }
                })
        },
        async action_terminateSession(payload) {
            const request = {
                "request": 1009,
                "subRequest": 10014,
                "account": {
                    "id": this.id,
                    "credential": this.credential,
                    "session": {
                        "credential": payload.credential
                    }
                },
                "client": client
            };
            //console.log(request)
            return await api.post('', request)
                .then((res) => {
                    if (res.status === 200) {
                        return res.data
                    } else {
                        console.log('response is ' + res.status)
                    }
                }).then((data) => {
                    //console.log('action_terminateSession', data)
                    if (data.response === 100) {
                        return true
                    } else if (data.response === -100002) {
                        this.resetUser('your credential is expired')
                        router.push({name: 'Login'})
                    } else {
                        this.errorMessage = data.message
                        return false
                    }
                })
        },
        resetUser(errorMessage = '') {
            this.credential = ''
            this.id = 0
            this.username = ''
            this.permissions = []
            this.errorMessage = errorMessage
        }
    },
    persist: {
        paths: ['credential', 'id', 'username', 'preferences', 'permissions'],
    },
})


