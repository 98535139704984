// src/services/ability.js
import {AbilityBuilder, Ability} from '@casl/ability';

export function createAbility(permissions) {
    const {can, rules} = new AbilityBuilder(Ability);
    if (permissions.length === 0) {
        // Default permissions if permissions array is empty
        can('manage', ['NotFound', 'home', 'profile', 'connected-devices', 'identity-verification', 'financial', 'forbidden', 'discord-otp', 'my-orders']);
    } else {
        permissions.forEach(permission => {
            switch (permission) {
                case "KYC_MANAGEMENT":
                    can('manage', ['verify-kyc', 'verify-user']);
                    break;
                case "ORDERS_ACCOUNT_ACCESS":
                    can('manage', ['account-orders', 'games-account']);
                    break;
                case "ORDERS_BOOST_ACCESS":
                    can('manage', ['boost-orders', 'games-boost']);
                    break;
                case "ORDERS_CURRENCY_ACCESS":
                    can('manage', ['currency-buy-orders', 'currency-sell-orders', 'games-currency-buy', 'games-currency-sell']);
                    break;
                case "GAME_ACCOUNTS_ACCESS":
                    can('manage', 'games-account');
                    break;
                case "GAME_BOOST_ACCESS":
                    can('manage', 'games-boost');
                    break;
                case "GAME_CURRENCY_ACCESS":
                    can('manage', 'games-currency-buy', 'games-currency-sell');
                    break;
                default:
                    can('manage', [
                        'NotFound',
                        'home',
                        'profile',
                        'connected-devices',
                        'identity-verification',
                        'financial', 'forbidden',
                        'discord-otp',
                        'my-orders',
                        'update-account-order',
                        'update-boost-order',
                        'update-currencyBuy-order',
                        'update-currencySell-order',
                        'boost-orders-applicants',
                        'manage-applicants-boost-order',
                        'manage-applicants-account-orders',
                        'manage-applicants-account-order',
                        'manage-applicants-currency-buy-orders',
                        'manage-applicants-currency-buy-order',
                        'currency-sell-orders-applicants',
                        'manage-applicants-currency-sell-order',
                        'tab-accordion'
                    ]);
            }
        });
    }

    return rules; // Return the rules array
}
