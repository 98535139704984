import {defineStore} from 'pinia';
import packageJson from "../../package.json";
import api from "@/api";
import {useUserStore} from "@/stores/user";

export const client = {
    appName: "Boostaminophen",
    platform: "Web",
    market: "Direct",
    language: "EN",
    version: packageJson.version
}

export const useConfigStore = defineStore('config', {
    state: () => ({
        configs: {
            games: {
                boost: null,
                account: null,
                currency: null
            },
            stores: null
        }
    }),
    getters: {
        getGames(state) {
            return state.configs.games;
        },
        getBoostGames(state) {
            return state.configs.games.boost;
        },
        getAccountGames(state) {
            return state.configs.games.account;
        },
        getCurrencyGames(state) {
            return state.configs.games.currency;
        },
    },
    actions: {
        async getConfigs() {
            const userStore = useUserStore()
            const request = {
                "request": 10034,
                "client": client
            };
            try {
                await api.post('', request)
                    .then((res) => {
                        if (res.status === 200) {
                            return res.data
                        } else {
                            console.log('response is ' + res.status)
                        }
                    }).then((data) => {
                        console.log('getConfigs', data)
                        if (data.response === 100) {
                            //this.configs.games = data.games
                            this.configs.games.currency = data.games.filter(game => game.hasCurrencyService === true);
                            this.configs.games.account = data.games.filter(game => game.hasAccountService === true);
                            this.configs.games.boost = data.games.filter(game => game.hasBoostService === true);
                            this.configs.stores = data.stores
                        } else {
                            userStore.errorMessage = data.message
                            return false
                        }
                    })
            } catch (error) {
                console.log(error);

                /*if (axios.isAxiosError(error)) {
                    if (error.response) {
                        console.error('Server responded with status:', error.response.status);
                    } else if (error.request) {
                        if (error.message === 'Network Error') {
                            console.error('The network is aborted');
                            return "network error"
                        }
                    }
                } else {
                    // Handle non-Axios errors
                    console.error('An error occurred:', error.message);
                }*/
            }

        },
        async getAllPermissions() {
            const userStore = useUserStore()
            const request = {
                "request": 10019,
                "client": {
                    ...client,
                    appName: "Boostaminophen Manager",
                }
            };
            console.log(request)
            try {
                await api.post('', request)
                    .then((res) => {
                        if (res.status === 200) {
                            return res.data
                        } else {
                            console.log('response is ' + res.status)
                        }
                    }).then((data) => {
                        if (data.response === 100) {
                            console.log('getAllPermissions', data)
                        } else {
                            userStore.errorMessage = data.message
                            return false
                        }
                    })
            } catch (error) {
                console.log(error);

                /*if (axios.isAxiosError(error)) {
                    if (error.response) {
                        console.error('Server responded with status:', error.response.status);
                    } else if (error.request) {
                        if (error.message === 'Network Error') {
                            console.error('The network is aborted');
                            return "network error"
                        }
                    }
                } else {
                    // Handle non-Axios errors
                    console.error('An error occurred:', error.message);
                }*/
            }

        },
    },
})
