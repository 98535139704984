import {createRouter, createWebHistory} from 'vue-router'
import auth from "@/middleware/auth";
import middlewarePipeline from "@/middleware/middlewarePipeline";
import {useUserStore} from "@/stores/user";
import {config} from "@/config";
import {useThemeSettingsStore} from "@/stores/themeSettings.js";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    base: import.meta.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    },
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/views/auth/login/index.vue')
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import("@/views/auth/forgot-password.vue"),
        },
        {
            path: "/register",
            name: "reg",
            component: () => import("@/views/auth/register/index.vue"),
        },
        {
            path: "/:catchAll(.*)",
            name: "404",
            component: () => import("@/views/404.vue"),
        },
        {
            path: '/otp',
            name: 'discord-otp',
            component: () => import('@/views/otp.vue'),
        },
        {
            path: "/",
            name: "Dashboard",
            redirect: "/profile",
            component: () => import("@/Layout/index.vue"),
            meta: {
                middleware: [auth],
            },
            children: [
                {
                    path: "blank-page",
                    name: "blank-page",
                    component: () => import("@/views/blank-page.vue"),
                },
                {
                    path: "home",
                    name: "home",
                    component: () => import("@/views/home/index.vue"),
                    meta: {
                        hide: true,
                    },
                },
                {
                    path: "profile",
                    name: "profile",
                    component: () => import("@/views/profile"),
                    meta: {
                        groupParent: "account",
                    },
                },
                {
                    path: 'counter',
                    name: 'counter',
                    component: () => import('@/views/Counter.vue')
                },
                {
                    path: 'orders',
                    name: 'orders',
                    component: () => import('@/views/orders/index.vue'),
                    meta: {
                        groupParent: "orders",
                    },
                    children: [
                        {
                            path: 'boost',
                            name: 'boost-orders',
                            component: () => import('@/views/orders/boost.vue'),
                            /*beforeEnter: (to, from, next) => {
                                if (useUserStore().getBoostGamePreferences.length) {
                                    next()
                                } else {
                                    next({name: 'games-boost'})
                                }
                            }*/
                        },
                        {
                            path: 'account',
                            name: 'account-orders',
                            component: () => import('@/views/orders/account.vue'),
                            /*beforeEnter: (to, from, next) => {
                                if (useUserStore().getAccountGamePreferences.length) {
                                    next()
                                } else {
                                    next({name: 'games-account'})
                                }
                            }*/
                        },
                        {
                            path: 'currency-buy',
                            name: 'currency-buy-orders',
                            component: () => import('@/views/orders/currencyBuy.vue'),
                            /*beforeEnter: (to, from, next) => {
                                if (useUserStore().getCurrencyBuyGamePreferences.length) {
                                    next()
                                } else {
                                    next({name: 'games-currency-buy'})
                                }
                            }*/
                        },
                        {
                            path: 'currency-sell',
                            name: 'currency-sell-orders',
                            component: () => import('@/views/orders/currencySell.vue'),
                            /*beforeEnter: (to, from, next) => {
                                if (useUserStore().getCurrencySellGamePreferences.length) {
                                    next()
                                } else {
                                    next({name: 'games-currency-sell'})
                                }
                            }*/
                        },
                    ]
                },
                {
                    path: 'manage-applicants',
                    name: 'manage-applicants',
                    component: () => import('@/views/manageApplicants/index.vue'),
                    meta: {
                        groupParent: "orders applicants",
                    },
                    children: [
                        {
                            path: 'boost',
                            children: [
                                {
                                    path: '',
                                    name: 'boost-orders-applicants',
                                    component: () => import('@/views/manageApplicants/boost/orders.vue'),
                                },
                                {
                                    path: ':orderID',
                                    name: 'manage-applicants-boost-order',
                                    component: () => import('@/views/manageApplicants/boost/applicants.vue'),
                                    meta: {
                                        hide: true,
                                    }
                                }
                            ]
                        },
                        {
                            path: 'account',
                            children: [
                                {
                                    path: '',
                                    name: 'manage-applicants-account-orders',
                                    component: () => import('@/views/manageApplicants/account/orders.vue'),
                                },
                                {
                                    path: ':orderID',
                                    name: 'manage-applicants-account-order',
                                    component: () => import('@/views/manageApplicants/account/applicants.vue'),
                                    meta: {
                                        hide: true,
                                    }
                                }
                            ]
                        },
                        {
                            path: 'currency-buy',
                            children: [
                                {
                                    path: '',
                                    name: 'manage-applicants-currency-buy-orders',
                                    component: () => import('@/views/manageApplicants/currencyBuy/orders.vue'),
                                },
                                {
                                    path: ':orderID',
                                    name: 'manage-applicants-currency-buy-order',
                                    component: () => import('@/views/manageApplicants/currencyBuy/applicants.vue'),
                                    meta: {
                                        hide: true,
                                    }
                                }
                            ]
                        },
                        {
                            path: 'currency-sell',
                            children: [
                                {
                                    path: '',
                                    name: 'currency-sell-orders-applicants',
                                    component: () => import('@/views/manageApplicants/currencySell/orders.vue'),
                                },
                                {
                                    path: ':orderID',
                                    name: 'manage-applicants-currency-sell-order',
                                    component: () => import('@/views/manageApplicants/currencySell/applicants.vue'),
                                    meta: {
                                        hide: true,
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    path: 'games',
                    name: 'games',
                    component: () => import('@/views/games/index.vue'),
                    meta: {
                        groupParent: "games",
                    },
                    children: [
                        {
                            path: 'boost',
                            name: 'games-boost',
                            component: () => import('@/views/games/boost.vue'),
                        },
                        {
                            path: 'currency-buy',
                            name: 'games-currency-buy',
                            component: () => import('@/views/games/currencyBuy.vue'),
                        },
                        {
                            path: 'currency-sell',
                            name: 'games-currency-sell',
                            component: () => import('@/views/games/currencySell.vue'),
                        },
                        {
                            path: 'account',
                            name: 'games-account',
                            component: () => import('@/views/games/account.vue'),
                        }
                    ]
                },
                {
                    path: 'my-orders',
                    meta: {
                        groupParent: "orders",
                    },
                    children: [
                        {
                            path: '',
                            name: 'my-orders',
                            component: () => import('@/views/orders/myOrders/index.vue'),
                        },
                        {
                            path: 'update/account/:id/:orderTitle',
                            name: 'update-account-order',
                            component: () => import('@/views/orders/myOrders/account/update.vue')
                        },
                        {
                            path: 'update/boost/:id',
                            name: 'update-boost-order',
                            component: () => import('@/views/orders/myOrders/boost/update2.vue')
                        },
                        {
                            path: 'update/currencyBuy/:id/:orderTitle',
                            name: 'update-currencyBuy-order',
                            component: () => import('@/views/orders/myOrders/currencyBuy/update.vue')
                        },
                        {
                            path: 'update/currencySell/:id',
                            name: 'update-currencySell-order',
                            component: () => import('@/views/orders/myOrders/currencySell/update.vue')
                        }
                    ]
                },
                {
                    path: 'devices',
                    name: 'connected-devices',
                    component: () => import('@/views/profile/sessions.vue'),
                    meta: {
                        groupParent: "account",
                    },
                },
                {
                    path: 'identity-verification',
                    name: 'identity-verification',
                    component: () => import('@/views/profile/identityVerification.vue'),
                    meta: {
                        groupParent: "account"
                    },
                },
                {
                    path: 'financial',
                    name: 'financial',
                    component: () => import('@/views/profile/financial.vue'),
                    meta: {
                        groupParent: "account"
                    },
                },
                {
                    path: 'verify-kyc',
                    name: 'verify-kyc',
                    component: () => import('@/views/kyc/index.vue'),
                    meta: {
                        groupParent: "kyc management",
                        animation: ''
                    },
                },
                {
                    path: 'verify-user',
                    name: 'verify-user',
                    component: () => import('@/views/kyc/accountDetails/index.vue'),
                    meta: {
                        hide: true,
                        animation: ''
                    },
                },
                {
                    path: 'chat',
                    name: 'chat',
                    component: () => import('@/views/blank-page.vue'),
                },
                {
                    path: 'notifications',
                    name: 'notifications',
                    component: () => import('@/views/blank-page.vue'),
                },
                {
                    path: '/forbidden',
                    name: 'forbidden',
                    component: () => import('@/views/Forbidden.vue'),
                    meta: {
                        hide: true,
                    },
                },
            ]
        }
    ]
})

router.beforeEach((to, from, next) => {
    const themeSettingsStore = useThemeSettingsStore()
    if (to.name === 'verify-user' || to.name === 'verify-kyc') {
        to.meta.animation = themeSettingsStore.getLanguage === 'fa' ? 'singleUser-fa' : 'singleUser';
    }
    const titleText = to.name;
    const words = titleText.split(" ");
    const wordsLength = words.length;
    if (titleText) {
        for (let i = 0; i < wordsLength; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
    }
    document.title = words + " - " + config.siteName

    /** Navigate to next if middleware is not applied */
    if (!to.meta.middleware) {
        return next()
    }

    const middleware = to.meta.middleware;
    const gate = to.meta.gate;
    const context = {to, from, next, gate}
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});
export default router
