<template>
  <RouterView/>
</template>

<script setup>
import {RouterView} from 'vue-router'
import {onMounted} from "vue";
import {useConfigStore} from "@/stores/config.js";
import {useUserStore} from "@/stores/user.js";
import router from "@/router/index.js";

const configStore = useConfigStore();
const userStore = useUserStore();

async function getConfigs() {
  await configStore.getConfigs();
}

async function getBasicProfile() {
  if (userStore.getUserId !== 0) {
    await userStore.action_getBasicProfile();
  }/* else {
    await router.push({name: "Login"})
  }*/
}

onMounted(async () => {
  await getConfigs();
  await getBasicProfile();
})
</script>

<style scoped>
</style>
